.curve { 
	float: left;
	margin-right:3vw; 
    shape-outside:circle();
    width: 15vw;
    height: 15vw;
    border-radius: 20vw;
    border: 0.75vw #ffffff solid;
}
.paragraph{
    text-align: left;
    margin-left: 1vw;
    max-width: 75vw;
    text-justify: justify;
    word-spacing: .5vh;
    font-size: 2rem;
}
.about-page{
    margin: auto;
    margin-top: 10vh;
    z-index: 3;
}

.about-title{
    text-align: left;
    margin: auto;
    margin-bottom:1vh;
    font-size: 3rem;
}

@media only screen 
and (max-device-width : 480px) {
    .curve { 
        float: left;
        margin-right:4vh; 
        shape-outside:circle();
        width: 12vh;
        height: 12vh;
        border-radius: 12vh;
        border: 0.5vh #ffffff solid;
    }
    .paragraph{
        min-width: 100%;
        word-spacing: .5vh;
        font-size: 1.1rem;
    }
    
    .about-page{
        width: 85%;
        margin-top: 5vh;
        z-index: 3;
    }
    .about-title{
        margin: auto;
        margin-bottom:1vw;
        font-size: 1.6rem;
    }
} 