.link-page{
    z-index: 3;
}
  
.link-page{
    display: flex;
    margin: auto;
    margin-top: 15vh;
    flex-direction: column;
}
.link-component{
    display: flex;
    flex-direction: column;
    min-width: 50vh;
}

.link-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: auto;
    margin-top: 2vh;
    margin-bottom: 2vh;
    min-width: 30%;
}


.contact-bubble{
    border-radius: 42vw;
    display: flex;
}
.left-bubble{
    animation-timing-function:  linear;
    animation-duration: 20s;
    animation-name: breathe;
    animation-iteration-count: infinite;
    animation-delay: 2s;
}
.center-bubble{
    animation-timing-function:  linear;
    animation-duration: 20s;
    animation-name: breathe;
    animation-iteration-count: infinite;
    animation-delay: 5s;
}
.right-bubble{
    animation-timing-function:  linear;
    animation-duration: 20s;
    animation-name: breathe;
    animation-iteration-count: infinite;
    animation-delay: 7s;
}

.inner-contact-bubble{
    cursor: pointer;  
    display: flex;
    border-radius: 41vw;
    border: 0.8vh #ffffff solid;
    background: radial-gradient(#379683ca, #379683ca, #056b5f, #05386b);
    width: 19vh;
    height: 19vh;
    margin: auto;
}

.inner-contact-bubble:hover{
    background-color:#74c1b2;
}

.contact-name{
    margin: auto;
    margin-left: 1vh;
    margin-right: 1vh;
    font-size: 2rem;
}

  .bubble-icon{
    font-size: 11vh;
  }
  .bubble-icon-height{
    font-size: 11vh;
}

.bubble-link{
    margin: auto;
    
}

.decentered{
    position: relative;
    bottom: 25%;
}

@media only screen 
and (max-device-width : 480px) {

    .link-page{
        display: flex;
        margin-top: 20vw;
        flex-direction: column;
    }
    .link-component{
        display: flex;
        flex-direction: column;
        min-width: 25vw;
    }

    .link-row{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: auto;
        margin-top: 3vh;
        margin-bottom: 5vh;
        min-width: 30%;
    }

    .contact-bubble{
        margin: auto;
        border-radius: 42vw;
        display: flex;
    }
    
    .inner-contact-bubble{
        cursor: pointer;  
        display: flex;
        border-radius: 41vw;
        border: 0.6vh #ffffff solid;
        width: 18vw;
        height: 18vw;
        margin: auto;
    }

    .contact-name{
        margin: auto;
        margin-left: 1vw;
        margin-right: 1vw;
        font-size: 6vw;
    }
    
    .bubble-icon{
        font-size: 8vw;
        margin: auto;
    }

    .bubble-icon-height{
        font-size: 8vw;
    }

    .bubble-link{
        margin: auto;
    }
    .contact-name{
        font-size: 1rem;
    }


}
