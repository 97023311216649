.works-page{
    margin: auto;
    z-index: 3;
}

.works-link{
    color: #ffffff;
}

@media only screen 
and (min-device-width : 320px) 
and (max-device-width : 480px) {
    
    .works-page{
        max-width: 85%;
        min-width: 85%;
        margin-top: 5vh;
        z-index: 3;
    }

} 