.link-component {

    animation-timing-function:  ease-in-out;
    animation-duration: 7s;
    animation-name: bubble;
    animation-iteration-count: infinite;
}
.single-link-component{

    animation-timing-function:  ease-in-out;
    animation-duration: 7s;
    animation-name: bubble;
    animation-iteration-count: infinite;
}
.single-link-component:nth-child(){

    animation-timing-function:  ease-in-out;
    animation-duration: 7s;
    animation-name: bubble;
    animation-iteration-count: infinite;
}

.inner-contact-bubble{
    animation-timing-function:  ease-in-out;
    animation-duration: 5s;
    animation-name: scale;
    animation-iteration-count: infinite;
}

@keyframes bubble {
    100% {
      transform: translate3d(0, 0, 0) rotate(0) ;
    }
    50% {
    transform: translate3d(0, 5px, 0) rotate(0) ;
      }
    0%{
      transform: translate3d(0, 0, 0) rotate(0);
    }
  }

@keyframes scale {
    100% {
      transform: scale(1) ;
    }
    50% {
    transform: scale(1.01) ;
      }
    0%{
      transform: scale(1);
    }
  }