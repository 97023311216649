

a:link {
    color: #ffffff;
  }
  
  a:visited {
    color: #ffffff;
    text-decoration: none;
  }
  
  a:hover {
    color: #ffffff;
  }
  
  a:active {
    color: #ffffff;
  }

.App-header-row{
    display: flex;
}

.header-content{
  text-transform: uppercase;
}

  .App-header-row{
      display: flex;
      justify-content: flex-start;
      margin-bottom: 1vh;
  }

  .header-content{
    display: flex;
    border-bottom: 0.75vh white solid;
    background-color: #022446dd;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin: auto;
    cursor: pointer;
  }

  .header-content:hover{
    background-color: #60c5b1b7;
  }
  
  .custom-link{
    display: flex;
    margin: auto;
    padding: 1vh 1vw;
    border-radius: 14px;
    margin-left: .25vw;
    margin-right: .25vw;
    background-color: #022446dd;
    border-bottom: 0.35vh white solid;
  }
  .custom-link:hover{
    background-color: #60c5b1b7;
  }
  
  .App-header {
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    color: white;
    font-weight: bolder;
    z-index: 1;
  }
  
  .header-content{
    display: flex;
    padding: 1vh 1vh;
    border-radius: 14px;
    margin-left: .5vw;
    margin-right: .5vw;
    font-weight: bolder;
    cursor: pointer;
    width: 22vw;
    height: 10vh;
  }


  .contact-row{
    font-size: 4vh;
    margin: auto;
    display: flex;
    justify-content: flex-start;
}


@media only screen 
and (max-device-width : 480px) { 

  .App-header {
    display: flex;
    font-size: 1.2rem;
    color: white;
    
  }
  
  .header-content{
    width: 27vw;
    height: 6vh;
    display: flex;
    font-size: 1.6rem;
    align-items: center;
    margin-left: .5vw;
    margin-right: .5vw;
  }
  .custom-link{
    height: 3vh;
    width: 3vh;
    font-size: 3vh;
    align-items: center;
    text-align: center;
    padding: 1vh;
  }

  .contact-row{
    z-index: 10;
}

} 
