
  

  
  $pointSize: .66vh;
  $animationDuration: 33s;
  $amount: 1000;
  .lower-particles {

    z-index: 0;
    width: $pointSize;
    height: $pointSize;
    border-radius: $pointSize*2;
    backface-visibility: hidden;
    position: absolute;
    animation-timing-function:  linear;
    animation-duration: $animationDuration;
    animation-name: goUp;
    animation-iteration-count: infinite;
    $colorRange: (
      #001b36,
      #24ffd3,
      #d4ffe7,
    );
    @for $i from 1 through $amount {
      &:nth-child(#{$i}) {
        color: nth($colorRange, random(length($colorRange)));
        bottom: 5 + random(80) * 0.8% ;
        left: random(98) * 1%;
        animation-duration: (random($animationDuration * 3s) / 10) * 1s + 60s;
        animation-delay: random(($animationDuration + 3s) * 10) / 1 * -60s;
        transform-origin: (random(100) - 10) * 0.9vw ((random(60) - 25) * 0.80vh + 10vh);
        box-shadow: ($pointSize) 0  currentColor;
        overflow: hidden;

      }
    }
  }

  .upper-particles {
    overflow: hidden;
    z-index: 0;
    width: $pointSize/2;
    height: $pointSize/2;
    border-radius: $pointSize ;
    backface-visibility: hidden;
    position: absolute;
    animation-timing-function:  linear;
    animation-duration: $animationDuration;
    animation-name: move;
    animation-iteration-count: infinite;
    $colorRange: (
      #001b36,
      #24ffd3,
      #d4ffe7,
    );
    @for $i from 1 through $amount {
      &:nth-child(#{$i}) {
        color: nth($colorRange, random(length($colorRange)));
        top: random(15) * 1%;
        left: random(96) * 1%;
        animation-duration: (random($animationDuration * 10) / 10) * 1s + 100s;
        animation-delay: random(($animationDuration + 10s) * 10) / 10 * -100s;
        transform-origin: (random(3) - 0) * 0.8vw (random(3) - 0) * 0.9vh;
        box-shadow: ($pointSize) 0  currentColor;
        overflow: hidden;

      }
    }
  }


  
  @keyframes move {
    100% {
      transform: translate3d(0, 0, 0) rotate(720deg ) scale(.95);
    }
    50%{
      transform: scale(3);
    }
    0%{
      transform: scale(1);
    }
  }
  @keyframes goUp {
    90% {
      $degree: random(360);
      transform: translate3d(1vh, 5vh, 0) rotate(0) ;
    }
    0%{
      transform: translate3d(1vh, 85vh, 0) rotate(0);
    }
  }


  
  @media only screen 
  and (min-device-width : 720px) {
    $pointSize: .4vh;
    $animationDuration: 33s;
    $amount: 1000;
    .upper-particles {
      overflow: hidden;
  
      z-index: 0;
      width: $pointSize;
      height: $pointSize;
      border-radius: $pointSize*2;
      backface-visibility: hidden;
      position: absolute;
      animation-timing-function:  linear;
      animation-duration: $animationDuration;
      animation-name: move;
      animation-iteration-count: infinite;
      $colorRange: (
        #001b36,
        #24ffd3,
        #d4ffe7,
      );
      @for $i from 1 through $amount {
        &:nth-child(#{$i}) {
          color: nth($colorRange, random(length($colorRange)));
          top: random(15) * 1%;
          left: random(100) * 1%;
          animation-duration: (random($animationDuration * 10) / 10) * 1s + 100s;
          animation-delay: random(($animationDuration + 10s) * 10) / 10 * -100s;
          transform-origin: (random(3) - 0) * 0.8vw (random(3) - 0) * 0.9vh;
          box-shadow: ($pointSize) 0  currentColor;
          overflow: hidden;
  
        }
      }
    }
  }

  