.App {
  text-align: center;
  background: linear-gradient(to bottom, #05386b, #379683, #5cdb95);
  color: white;
  display: flex;
  flex-direction: column;
  z-index: 1;
  font-weight: bold;

}

body {
  margin: 0;
  z-index: 1;
  width: 100vw;
  overflow-x: hidden;
}

